












import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import PeriodCloseFirst from '@/components/periodClose/PeriodCloseFirst.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';

@Component({
  components: {
    PeriodCloseFirst,
    UiPageHeader,
  },
})
export default class PeriodClose extends BaseVue {}
